@use 'utilities/colors' as *;

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	height: 100vh;
	background-color: $mainColor;
	font-family: 'Open Sans', sans-serif;
	color: $textColor;
	overflow: auto;
}

.section-image {
	position: relative;
	width: 100%;
	height: 75vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	z-index: -1;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.heading {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 9rem;
	font-weight: 500;
	letter-spacing: 2px;
	color: $headerTextColor;
}

.second-heading {
	margin: 2em 0 1em;
	font-size: 5rem;
	color: $specialColor;
	font-weight: 500;
}

.sticky-body {
	overflow: hidden;
}

.wrapper {
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
}

.section-padding {
	padding: 1em 2em;
}

.section-margin {
	margin-top: 2em;
}

.text-style {
	margin-top: 2em;
	font-size: 2rem;
}

.underline-heading {
	width: 50%;
	height: 1px;
	background-color: $specialColor;
}

.underline {
	width: 100%;
	height: 1px;
	background-color: $specialColor;
}

// navigation

.nav-mobile {
	position: fixed;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	transform: translateY(-100%);
	width: 100%;
	height: 100vh;
	background-color: $mainColor;
	transition: 0.3s;
	z-index: 1;
	overflow: hidden;

	&__item {
		padding: 1em 2em;
		font-size: 2.3rem;
		font-weight: 500;
		color: $textColor;
		text-decoration: none;
		transition: color 0.15s;

		&:hover {
			color: $specialColor;
		}
	}
}

.navbar-active {
	transform: translateX(0);
}

.nav-desktop {
	display: none;
}

// header

.header {
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-image: url('../../dist/img/main-small.jpg');
	background-size: cover;
	background-position: center;
	z-index: 0;
	overflow: hidden;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		background-color: rgba(0, 0, 0, 0.5);
		z-index: -11;
	}

	&__heading {
		font-size: 7rem;
		font-weight: 500;
		letter-spacing: 2px;
		color: $headerTextColor;
		z-index: -10;
	}

	&__text,
	&__second-text {
		color: $headerTextColor;
	}

	&__text {
		font-size: 1.5rem;
		padding: 0.5em;
	}

	&__second-text {
		font-size: 2.5em;
		margin-top: 2.3em;
	}

	&__aboutus-link {
		text-decoration: none;
	}

	&__arr-box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.5em;
		padding: 0.2em;
		font-size: 4rem;
		color: $headerTextColor;
		border: 2px solid #ffffff;
		border-radius: 50%;
		cursor: pointer;
		overflow: hidden;
	}

	&__arr-box:hover &__arr {
		animation: moveArray ease-in-out 0.2s;
	}

	&__arr {
		font-size: 4rem;
		transition: 0.2s;
	}

	@keyframes moveArray {
		0% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(100%);
		}

		70% {
			transform: translateX(-100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
		}
	}
}

// scroll image

.scroll-img {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10em;
	background-image: url('../../dist/img/employers-with-mask.png');
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
	width: 100%;
	height: 450px;
	z-index: -1;

	&::before {
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.scroll-heading {
	text-align: center;
	font-weight: 400;
	font-style: italic;
	font-size: 4rem;
	color: $specialColor;
	z-index: 0;
}

// about us

.about-us .section-image {
	background-image: url('../../dist/img/about-us-mini.jpg');
}

.about-us {
	text-align: center;

	&__text,
	.second-heading {
		text-align: left;
	}

	&__why-us-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 5em 0;
		width: 100%;
	}

	&__why-us-box {
		display: flex;
		flex-direction: column;

		margin-top: 2em;
		padding: 1em 2em;
		width: 250px;
		height: 350px;
		border-radius: 8px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.226);
	}

	&__why-us-icon {
		margin-top: auto;
		font-size: 4rem;
		color: rgba($specialColor, 0.5);
	}

	&__offer-btn {
		margin: 3em;
		padding: 1em 2em;
		background-color: $specialColor;
		color: #ffff;
		font-size: 2rem;
		border: none;
		border-radius: 10px;
		cursor: pointer;
		transition: background-color 0.2s;

		&:hover {
			background-color: $hoverColor;
		}
	}
}

// offer

.offer .section-image {
	background-image: url('../../dist/img/offer-small.jpg');
}

.offer {
	&__description-box {
		margin-bottom: 4em;
	}

	&__text-box {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	&__img-container {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 2em;
		width: 100%;
	}

	&__img-box {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 350px;
		overflow: hidden;
	}

	&__img {
		object-fit: cover;
		background-position: center;
		width: 100%;
		height: 100%;
	}

	&__img-title {
		margin-top: 2em;
		font-size: 3rem;
		text-align: center;
		color: $specialColor;
	}
}

// contact

.contact .section-image {
	background-image: url('../../dist/img/contact-small.jpg');
}

.contact .text-style {
	text-align: center;
}

.contact {
	&__info-box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 5em 0;
	}

	&__info {
		display: flex;
		align-items: center;
	}

	&__info-text {
		display: none;
	}

	&__link {
		padding: 0.5em;
		font-size: 4rem;
		text-decoration: none;
		color: $specialColor;
	}

	&__form-box {
		width: 100%;
		max-width: 400px;
	}

	&__box {
		display: flex;
		flex-direction: column;
	}

	&__box input,
	&__box textarea,
	&__btn {
		padding: 1em;
		margin: 0.4em;
		border: 1px solid $specialColor;
		border-radius: 8px;
		font-family: inherit;
	}

	&__box textarea {
		min-height: 150px;
		resize: vertical;
	}

	&__btn {
		padding: 1em 2em;
		background: transparent;
		cursor: pointer;
		transition: background 0.2s, color 0.2s;

		&:hover {
			background: $specialColor;
			color: $headerTextColor;
		}
	}

	&__error-text {
		display: none;
		color: $errorColor;
		margin: 0.5em 0;
		font-size: 1.4rem;
	}

	&__check-checkbox {
		margin-right: 0.5em;
		width: 15px;
		height: 15px;
	}

	&__box-check {
		margin: 1em 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__popup {
		display: none;
		position: fixed;
		background-color: $popupColor;
		border: 1px solid;
		top: 10%;
		left: 50%;
		transform: translate(-50%);
		width: 300px;
		height: 200px;
		padding: 1em 2em;
		text-align: center;
		border-radius: 8px;
		border: 1px solid #7ec513;
	}

	&__popup-text {
		margin-top: 3em;
		font-size: 2rem;
	}
}

.error input,
.error textarea {
	background-color: $errorColorBackround;
	border: 2px solid $errorColor;
}

.error .contact__error-text {
	display: block;
}

.show-popup {
	display: block;
}

// private policy

.private-policy {
	&__list {
		font-size: 2rem;
		list-style: none;
		margin-left: 2.5em;
	}

	&__link {
		text-decoration: none;
		color: $textColor;
		transition: color 0.2s;
		&:hover {
			color: $specialColor;
		}
	}
}

.private-policy .heading {
	font-size: 5.5rem;
}

.private-policy .section-image {
	background-image: url('../../dist/img/main-small.jpg');
}

.private-policy .text-style {
	margin: 0.5em;
}

.private-policy .second-heading {
	margin-bottom: 1em;
	font-size: 3.5rem;
	color: $specialColor;
}

// footer

.footer {
	position: sticky;
	top: 100%;
	z-index: -1;

	&__box {
		padding-top: 2em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;
	}

	&__img {
		margin-top: 3em;
		width: 150px;
	}

	&__navi,
	&__media {
		font-size: 1.8rem;
	}

	&__navi-item,
	&__media-info {
		margin: 1em;
		list-style: none;
	}

	&__navi-item a {
		padding: 0.3em 1em;
		color: $textColor;
		text-decoration: none;
		transition: color 0.2s;

		&:hover {
			color: $specialColor;
		}
	}

	&__link {
		text-decoration: none;
	}

	&__media {
		margin: 2em 0;
	}

	&__media-info {
		display: none;
	}

	&__icon {
		color: $specialColor;
		font-size: 5rem;
		cursor: pointer;

		&:hover {
			color: $hoverColor;
		}
	}

	&__copyright-info {
		padding: 1em 0;
		font-size: 1.5rem;
		text-align: center;
		color: #555555;
	}

	&__copyright-info a {
		font-weight: bold;
		color: #555555;
		text-decoration: none;
		transition: color 0.2s;

		&:hover {
			color: $hoverColor;
		}
	}
}

.cookie-box {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 250px;
	background-color: $mainColor;
	text-align: center;
	font-size: 2rem;
	border-top: 2px solid $specialColor;
	opacity: 0;
	transform: translateY(100%);
	animation: show-cookie 0.5s 0.5s forwards;
}

.pagenofound {
	text-align: center;

	&__heading {
		margin: 0.5em 0 1em;
		font-size: 8rem;
		font-weight: normal;
		color: $specialColor;
	}

	&__icon {
		margin-bottom: 3em;
		width: 150px;
	}
}

.pagenofound .text-style {
	margin-bottom: 3em;
	font-size: 3rem;
}

.cookie-btn {
	padding: 0.5em 1em;
	margin-top: 2em;
	border: 1px solid $specialColor;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.2s;
}

.cookie-btn:hover {
	background-color: $specialColor;
}

.hide {
	display: none;
}

@keyframes show-cookie {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

// media

@media (min-width: 570px) {
	.offer {
		&__img-title {
			font-weight: 500;
			font-size: 4rem;
		}
	}
	.private-policy .heading {
		font-size: 8rem;
	}
}

@media (min-width: 768px) {
	.header {
		background-image: url('../../dist/img/main-big.jpg');

		&__heading {
			font-size: 11rem;
			font-weight: 500;
			letter-spacing: 2px;
			color: $headerTextColor;
			z-index: -10;
		}

		&__text {
			font-size: 2rem;
		}
	}

	.hamburger {
		display: none;
	}
	.shadow {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.nav-mobile {
		display: none;
	}

	.nav-desktop .wrapper {
		margin: auto;
		left: 0;
		width: 100%;
		max-width: 1400px;
		display: flex;
		justify-content: flex-end;
	}

	.nav-desktop {
		position: fixed;
		height: 80px;
		width: 100%;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		z-index: 1;

		&__item {
			position: relative;
			margin: 0.5em;
			padding: 0.3em;
			font-size: 2.3rem;
			font-weight: 500;
			color: $specialColor;
			text-decoration: none;
			overflow: hidden;
		}

		&__item::before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			height: 2px;
			width: 100%;
			background-color: $specialColor;
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 0.2s;
		}

		&__item:hover::before {
			transform: scaleX(1);
			transform-origin: left;
		}

		&__item.active::before {
			transform: scaleX(1);
			transform-origin: left;
		}
	}

	.about-us {
		&__why-us-container {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-evenly;
			width: 100%;
			flex-direction: row;
		}
	}

	.contact {
		&__link {
			display: flex;
			align-items: center;
		}
		&__info-text {
			display: block;
			margin-left: 0.3em;
			font-size: 2rem;
		}

		&__link {
			padding: 0.5em;
			font-size: 4rem;
			text-decoration: none;
			color: $specialColor;
			transition: color 0.2s;

			&:hover {
				color: $hoverColor;
			}
		}

		&__form-box {
			width: 100%;
			max-width: 450px;
		}

		&__popup {
			position: fixed;
			background-color: $popupColor;
			border: 1px solid;
			top: 10%;
			left: 50%;
			transform: translate(-50%);
			width: 600px;
			height: 200px;
			padding: 1em 2em;
			text-align: center;
			border-radius: 8px;
			border: 1px solid #7ec513;
		}

		&__popup-text {
			font-size: 2.3rem;
		}
	}

	.private-policy .section-image {
		background-image: url('../../dist/img/main-big.jpg');
	}

	.footer {
		&__box {
			width: 100%;
			max-width: 1600px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
		}

		&__media {
			flex-direction: row;
		}

		&__logo,
		&__navi,
		&__media {
			width: 33%;
		}
	}
}

@media (min-width: 992px) {
	.about-us .section-image {
		background-image: url('../../dist/img/about-us-big.jpg');
	}

	.about-us {
		&__why-us-container {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
		}

		&__why-us-box {
			margin: 2em;
		}
	}

	.contact {
		&__form-box {
			width: 100%;
			max-width: 550px;
		}

		&__info-box {
			justify-content: space-around;
			align-items: start;
			flex-direction: row;
		}

		&__info {
			display: flex;
			align-items: flex-start;
			margin: 0;
			flex-direction: column;
			order: 1;
		}

		&__link {
			padding: 0;
		}
		&__link:last-child {
			margin-top: 0.5em;
		}
	}
}

@media (min-width: 1150px) {
	.about-us {
		&__why-us-container {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
		}

		&__why-us-box {
			margin: auto;
		}
	}

	.offer {
		.offer .section-image {
			background-image: url('../../dist/img/offer-big.jpg');
		}

		.offer {
			&__container-text:nth-child(2) {
				.offer__img-box {
					order: 2;
				}
				.offer__description-box {
					order: 1;
				}
			}

			&__img {
				width: 600px;
			}

			&__img-title {
				margin-top: 0;
				font-size: 4rem;
				text-align: left;
			}

			&__text-box {
				display: flex;

				justify-content: space-between;
				flex-direction: row;
				margin: 5em 0;
				text-align: left;
			}

			&__description-box {
				width: 600px;
				padding: 0 3em;
			}

			&__description-box .text-style {
				margin-top: 1em;
			}
		}
	}
}
