*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  height: 100vh;
  background-color: #fdfdfd;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  overflow: auto;
}

.section-image {
  position: relative;
  width: 100%;
  height: 75vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: -1;
}
.section-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.heading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 9rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: #ffffff;
}

.second-heading {
  margin: 2em 0 1em;
  font-size: 5rem;
  color: #f0aa00;
  font-weight: 500;
}

.sticky-body {
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.section-padding {
  padding: 1em 2em;
}

.section-margin {
  margin-top: 2em;
}

.text-style {
  margin-top: 2em;
  font-size: 2rem;
}

.underline-heading {
  width: 50%;
  height: 1px;
  background-color: #f0aa00;
}

.underline {
  width: 100%;
  height: 1px;
  background-color: #f0aa00;
}

.nav-mobile {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transform: translateY(-100%);
  width: 100%;
  height: 100vh;
  background-color: #fdfdfd;
  transition: 0.3s;
  z-index: 1;
  overflow: hidden;
}
.nav-mobile__item {
  padding: 1em 2em;
  font-size: 2.3rem;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  transition: color 0.15s;
}
.nav-mobile__item:hover {
  color: #f0aa00;
}

.navbar-active {
  transform: translateX(0);
}

.nav-desktop {
  display: none;
}

.header {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../dist/img/main-small.jpg");
  background-size: cover;
  background-position: center;
  z-index: 0;
  overflow: hidden;
}
.header::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -11;
}
.header__heading {
  font-size: 7rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: #ffffff;
  z-index: -10;
}
.header__text, .header__second-text {
  color: #ffffff;
}
.header__text {
  font-size: 1.5rem;
  padding: 0.5em;
}
.header__second-text {
  font-size: 2.5em;
  margin-top: 2.3em;
}
.header__aboutus-link {
  text-decoration: none;
}
.header__arr-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  padding: 0.2em;
  font-size: 4rem;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}
.header__arr-box:hover .header__arr {
  animation: moveArray ease-in-out 0.2s;
}
.header__arr {
  font-size: 4rem;
  transition: 0.2s;
}
@keyframes moveArray {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  70% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
}

.scroll-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10em;
  background-image: url("../../dist/img/employers-with-mask.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 450px;
  z-index: -1;
}
.scroll-img::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.scroll-heading {
  text-align: center;
  font-weight: 400;
  font-style: italic;
  font-size: 4rem;
  color: #f0aa00;
  z-index: 0;
}

.about-us .section-image {
  background-image: url("../../dist/img/about-us-mini.jpg");
}

.about-us {
  text-align: center;
}
.about-us__text,
.about-us .second-heading {
  text-align: left;
}
.about-us__why-us-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5em 0;
  width: 100%;
}
.about-us__why-us-box {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  padding: 1em 2em;
  width: 250px;
  height: 350px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.226);
}
.about-us__why-us-icon {
  margin-top: auto;
  font-size: 4rem;
  color: rgba(240, 170, 0, 0.5);
}
.about-us__offer-btn {
  margin: 3em;
  padding: 1em 2em;
  background-color: #f0aa00;
  color: white;
  font-size: 2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.about-us__offer-btn:hover {
  background-color: #cd9002;
}

.offer .section-image {
  background-image: url("../../dist/img/offer-small.jpg");
}

.offer__description-box {
  margin-bottom: 4em;
}
.offer__text-box {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.offer__img-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  width: 100%;
}
.offer__img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  overflow: hidden;
}
.offer__img {
  object-fit: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.offer__img-title {
  margin-top: 2em;
  font-size: 3rem;
  text-align: center;
  color: #f0aa00;
}

.contact .section-image {
  background-image: url("../../dist/img/contact-small.jpg");
}

.contact .text-style {
  text-align: center;
}

.contact__info-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5em 0;
}
.contact__info {
  display: flex;
  align-items: center;
}
.contact__info-text {
  display: none;
}
.contact__link {
  padding: 0.5em;
  font-size: 4rem;
  text-decoration: none;
  color: #f0aa00;
}
.contact__form-box {
  width: 100%;
  max-width: 400px;
}
.contact__box {
  display: flex;
  flex-direction: column;
}
.contact__box input, .contact__box textarea, .contact__btn {
  padding: 1em;
  margin: 0.4em;
  border: 1px solid #f0aa00;
  border-radius: 8px;
  font-family: inherit;
}
.contact__box textarea {
  min-height: 150px;
  resize: vertical;
}
.contact__btn {
  padding: 1em 2em;
  background: transparent;
  cursor: pointer;
  transition: background 0.2s, color 0.2s;
}
.contact__btn:hover {
  background: #f0aa00;
  color: #ffffff;
}
.contact__error-text {
  display: none;
  color: #da0000;
  margin: 0.5em 0;
  font-size: 1.4rem;
}
.contact__check-checkbox {
  margin-right: 0.5em;
  width: 15px;
  height: 15px;
}
.contact__box-check {
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact__popup {
  display: none;
  position: fixed;
  background-color: #d6f5a8;
  border: 1px solid;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  width: 300px;
  height: 200px;
  padding: 1em 2em;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #7ec513;
}
.contact__popup-text {
  margin-top: 3em;
  font-size: 2rem;
}

.error input,
.error textarea {
  background-color: rgba(255, 0, 0, 0.3490196078);
  border: 2px solid #da0000;
}

.error .contact__error-text {
  display: block;
}

.show-popup {
  display: block;
}

.private-policy__list {
  font-size: 2rem;
  list-style: none;
  margin-left: 2.5em;
}
.private-policy__link {
  text-decoration: none;
  color: #000000;
  transition: color 0.2s;
}
.private-policy__link:hover {
  color: #f0aa00;
}

.private-policy .heading {
  font-size: 5.5rem;
}

.private-policy .section-image {
  background-image: url("../../dist/img/main-small.jpg");
}

.private-policy .text-style {
  margin: 0.5em;
}

.private-policy .second-heading {
  margin-bottom: 1em;
  font-size: 3.5rem;
  color: #f0aa00;
}

.footer {
  position: sticky;
  top: 100%;
  z-index: -1;
}
.footer__box {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.footer__img {
  margin-top: 3em;
  width: 150px;
}
.footer__navi, .footer__media {
  font-size: 1.8rem;
}
.footer__navi-item, .footer__media-info {
  margin: 1em;
  list-style: none;
}
.footer__navi-item a {
  padding: 0.3em 1em;
  color: #000000;
  text-decoration: none;
  transition: color 0.2s;
}
.footer__navi-item a:hover {
  color: #f0aa00;
}
.footer__link {
  text-decoration: none;
}
.footer__media {
  margin: 2em 0;
}
.footer__media-info {
  display: none;
}
.footer__icon {
  color: #f0aa00;
  font-size: 5rem;
  cursor: pointer;
}
.footer__icon:hover {
  color: #cd9002;
}
.footer__copyright-info {
  padding: 1em 0;
  font-size: 1.5rem;
  text-align: center;
  color: #555555;
}
.footer__copyright-info a {
  font-weight: bold;
  color: #555555;
  text-decoration: none;
  transition: color 0.2s;
}
.footer__copyright-info a:hover {
  color: #cd9002;
}

.cookie-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 250px;
  background-color: #fdfdfd;
  text-align: center;
  font-size: 2rem;
  border-top: 2px solid #f0aa00;
  opacity: 0;
  transform: translateY(100%);
  animation: show-cookie 0.5s 0.5s forwards;
}

.pagenofound {
  text-align: center;
}
.pagenofound__heading {
  margin: 0.5em 0 1em;
  font-size: 8rem;
  font-weight: normal;
  color: #f0aa00;
}
.pagenofound__icon {
  margin-bottom: 3em;
  width: 150px;
}

.pagenofound .text-style {
  margin-bottom: 3em;
  font-size: 3rem;
}

.cookie-btn {
  padding: 0.5em 1em;
  margin-top: 2em;
  border: 1px solid #f0aa00;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cookie-btn:hover {
  background-color: #f0aa00;
}

.hide {
  display: none;
}

@keyframes show-cookie {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 570px) {
  .offer__img-title {
    font-weight: 500;
    font-size: 4rem;
  }
  .private-policy .heading {
    font-size: 8rem;
  }
}
@media (min-width: 768px) {
  .header {
    background-image: url("../../dist/img/main-big.jpg");
  }
  .header__heading {
    font-size: 11rem;
    font-weight: 500;
    letter-spacing: 2px;
    color: #ffffff;
    z-index: -10;
  }
  .header__text {
    font-size: 2rem;
  }
  .hamburger {
    display: none;
  }
  .shadow {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .nav-mobile {
    display: none;
  }
  .nav-desktop .wrapper {
    margin: auto;
    left: 0;
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-end;
  }
  .nav-desktop {
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    z-index: 1;
  }
  .nav-desktop__item {
    position: relative;
    margin: 0.5em;
    padding: 0.3em;
    font-size: 2.3rem;
    font-weight: 500;
    color: #f0aa00;
    text-decoration: none;
    overflow: hidden;
  }
  .nav-desktop__item::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: #f0aa00;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s;
  }
  .nav-desktop__item:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }
  .nav-desktop__item.active::before {
    transform: scaleX(1);
    transform-origin: left;
  }
  .about-us__why-us-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
  }
  .contact__link {
    display: flex;
    align-items: center;
  }
  .contact__info-text {
    display: block;
    margin-left: 0.3em;
    font-size: 2rem;
  }
  .contact__link {
    padding: 0.5em;
    font-size: 4rem;
    text-decoration: none;
    color: #f0aa00;
    transition: color 0.2s;
  }
  .contact__link:hover {
    color: #cd9002;
  }
  .contact__form-box {
    width: 100%;
    max-width: 450px;
  }
  .contact__popup {
    position: fixed;
    background-color: #d6f5a8;
    border: 1px solid;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
    width: 600px;
    height: 200px;
    padding: 1em 2em;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #7ec513;
  }
  .contact__popup-text {
    font-size: 2.3rem;
  }
  .private-policy .section-image {
    background-image: url("../../dist/img/main-big.jpg");
  }
  .footer__box {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .footer__media {
    flex-direction: row;
  }
  .footer__logo, .footer__navi, .footer__media {
    width: 33%;
  }
}
@media (min-width: 992px) {
  .about-us .section-image {
    background-image: url("../../dist/img/about-us-big.jpg");
  }
  .about-us__why-us-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .about-us__why-us-box {
    margin: 2em;
  }
  .contact__form-box {
    width: 100%;
    max-width: 550px;
  }
  .contact__info-box {
    justify-content: space-around;
    align-items: start;
    flex-direction: row;
  }
  .contact__info {
    display: flex;
    align-items: flex-start;
    margin: 0;
    flex-direction: column;
    order: 1;
  }
  .contact__link {
    padding: 0;
  }
  .contact__link:last-child {
    margin-top: 0.5em;
  }
}
@media (min-width: 1150px) {
  .about-us__why-us-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .about-us__why-us-box {
    margin: auto;
  }
  .offer .offer .section-image {
    background-image: url("../../dist/img/offer-big.jpg");
  }
  .offer .offer__container-text:nth-child(2) .offer__img-box {
    order: 2;
  }
  .offer .offer__container-text:nth-child(2) .offer__description-box {
    order: 1;
  }
  .offer .offer__img {
    width: 600px;
  }
  .offer .offer__img-title {
    margin-top: 0;
    font-size: 4rem;
    text-align: left;
  }
  .offer .offer__text-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 5em 0;
    text-align: left;
  }
  .offer .offer__description-box {
    width: 600px;
    padding: 0 3em;
  }
  .offer .offer__description-box .text-style {
    margin-top: 1em;
  }
}